


































import { Component, Vue, Prop } from "vue-property-decorator"
import ClassifiedAd from "~/models/ClassifiedAd"
import OcularPhone from "~/components/OcularPhone.vue"

@Component({
  components: {
    OcularPhone,
  },
})
export default class ClassifiedAdSummary extends Vue {
  @Prop({ required: true }) classifiedAd!: ClassifiedAd
}
