




















import { Vue, Component, Prop } from "vue-property-decorator"
import Agency from "~/models/Agency"

@Component({
  components: {},
})
export default class AgencyPickerItem extends Vue {
  @Prop({ required: true }) agency!: Agency
}
