
















































import { Vue, Component, Prop } from "vue-property-decorator"
import { Agency, Hit, agencies } from "~/config/algolia"
import AgencyPickerItem from "../../agencies/AgencyPickerItem.vue"
import CreateAgency from "../CreateAgency.vue"

@Component({
  components: {
    CreateAgency: () => import("@/components/modals/CreateAgency.vue"),
    AgencyPickerItem,
  },
})
export default class Agencies extends Vue {
  @Prop({ required: false, default: () => [] }) agencyIds!: number[]
  agencies: Hit<Agency>[] = []
  agencySelected: Agency | null = null
  inputValue = ""
  randomCreateAgencyKey = Math.random()

  rules = [(val: number | null) => val != null]

  mounted() {
    this.searchResults()
  }

  async searchResults() {
    const facetAgencies = this.agencyIds.map((id) => `objectID:${id}`)
    const response = await agencies.search(this.inputValue, {
      facetFilters: [facetAgencies, [`individual:${false}`]],
    })
    this.agencies = response.hits as Hit<Agency>[]
  }
  updateAgency() {
    this.$emit("updateAgency", this.agencySelected)
  }
  openCreateAgency() {
    this.randomCreateAgencyKey = Math.random()
    setTimeout(() => {
      const modal = this.$refs.createAgency as CreateAgency
      modal.open()
    }, 0)
  }
  agencyCreated(agency: Agency) {
    setTimeout(() => {
      this.inputValue = agency.name
      this.searchResults()
    }, 1000)
  }
}
