











































































































































































import { Vue, Component, Prop } from "vue-property-decorator"
import House from "~/models/House"
import Agent from "~/models/Agent"
import Agency from "~/models/Agency"
import OcularDialog from "~/components/OcularDialog.vue"
import PromptAgentNewAgency from "./PromptAgentNewAgency.vue"
import Agencies from "./agencyModal/Agencies.vue"
import Agents from "./agencyModal/Agents.vue"
import ClassifiedAds from "./agencyModal/ClassifiedAds.vue"
import snackbar from "~/plugins/snackbar"
import { Hit, agents, Agent as AlgoliaAgent } from "~/config/algolia"
import CreateAgent from "@/components/modals/CreateAgent.vue"
import ClassifiedAdSummary from "../house/ClassifiedAdSummary.vue"
import AgencyPickerItem from "../agencies/AgencyPickerItem.vue"
import AgentInfos from "../agents/AgentInfos.vue"

@Component({
  components: {
    ClassifiedAdSummary,
    CreateAgent,
    PromptAgentNewAgency,
    OcularDialog,
    Agencies,
    Agents,
    ClassifiedAds,
    AgencyPickerItem,
    AgentInfos,
  },
})
export default class AgencyModal extends Vue {
  @Prop({ required: true }) house!: House

  currentStep = 0
  classifiedAdIds: number[] = []
  agency: Agency | null = null
  agent: Agent | null = null
  individualAgencyId: number | null = null
  individualAgentId: number | null = null
  individual: boolean | null = null
  individualComment: string | null = this.house.individual_comment
  loading = false

  open() {
    this.dialog.open = true
  }
  get dialog() {
    return this.$refs.dialog as OcularDialog
  }
  get agentId() {
    return this.agent?.id
      ? this.agent?.id
      : this.individualAgentId
      ? this.individualAgentId
      : null
  }
  get agencyId() {
    return this.agency?.id
      ? this.agency?.id
      : this.individualAgencyId && this.individual
      ? this.individualAgencyId
      : null
  }
  get agentNewAgencyAssociation() {
    return this.$refs.agentNewAgencyAssociation as PromptAgentNewAgency
  }
  get isLastStep() {
    return (this.currentStep === 2 && this.individual) || this.currentStep === 3
  }
  get nextButtonLabel() {
    if (this.currentStep === 1) return null
    return this.isLastStep ? "J'ai terminé" : "Suivant"
  }
  get prevButtonLabel() {
    return this.currentStep === 0 ? "Annuler" : "Précédent"
  }
  get selectedClassifiedAds() {
    return this.house.history.filter(
      (history) =>
        history.agency_id == null &&
        history.id &&
        this.classifiedAdIds.find((id) => id === history.id)
    )
  }
  updateClassifiedAdIds(classifiedAdIds: number[]) {
    this.classifiedAdIds = classifiedAdIds
  }
  updateAgency(agency: Agency) {
    this.agency = agency
  }
  updateAgent(agent: Agent) {
    this.agent = agent
  }
  setPropertyHolderIsIndividual(isIndiv: boolean, goNext = false) {
    this.associateIndividual(isIndiv)
    if (goNext) this.nextStep()
  }
  async associateIndividual(individual: boolean) {
    if (individual) {
      const options = {
        filters: individual ? `individual:'${individual}'` : undefined,
      }
      const response = await agents.search("Particulier", options)
      const individualFromAlgolia = response.hits[0] as Hit<AlgoliaAgent>
      this.individualAgencyId = individualFromAlgolia.current_agency_id
      this.individualAgentId = individualFromAlgolia.id
    } else {
      this.agency = null
      this.agent = null
      this.individualAgencyId = null
      this.individualAgentId = null
    }
    this.individual = individual
  }
  async nextStep() {
    if (!this.isLastStep) this.currentStep++
    else {
      this.loading = true
      await this.associate()
      this.loading = false
      this.dialog.close()
    }
  }
  prevStep() {
    if (this.currentStep > 0) this.currentStep--
    else this.dialog.open = false
  }
  async associate() {
    if (this.agent && this.agency && this.agent.agency_id !== this.agencyId)
      this.agentNewAgencyAssociation.open()
    else await this.linkHouseToAgent(false)
  }
  openAgentEditor() {
    const editor = this.$refs.agentEditor as CreateAgent
    editor.open()
  }
  async linkHouseToAgent(openAgentEditor: boolean) {
    const previousAgencyId = this.agent?.agency_id ? this.agent.agency_id : null
    const success = await this.house.adAssociate(
      this.agencyId,
      this.agentId,
      this.classifiedAdIds,
      previousAgencyId,
      this.house.deal_id,
      this.individual,
      this.individualComment
    )
    if (success) {
      snackbar({
        color: "success",
        content: `L'association a bien été effectuée !`,
      })
      if (this.agent && this.agency?.name) {
        this.agent.agency_id = this.agency.id
        this.agent.agency_name = this.agency.name
      }
      if (openAgentEditor) this.openAgentEditor()
    }
  }
}
