


























































import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import { Hit, agents, Agent as AlgoliaAgent } from "~/config/algolia"
import Agent from "~/models/Agent"
import AgentInfos from "@/components/agents/AgentInfos.vue"
import CreateAgent from "../CreateAgent.vue"
import Agency from "~/models/Agency"

@Component({
  components: {
    AgentInfos,
    CreateAgent: () => import("@/components/modals/CreateAgent.vue"),
  },
})
export default class Agents extends Vue {
  @Prop() agency!: Agency | null
  agents: Agent[] = []
  agentSelected: Agent | null = null
  inputValue = ""
  randomCreateAgentKey = Math.random()
  searchAllAgencies = false

  mounted() {
    this.onChangeInput()
  }

  @Watch("agencyId")
  @Watch("inputValue")
  @Watch("searchAllAgencies")
  async onChangeInput() {
    if (this.agency) {
      const options = {
        filters:
          !this.searchAllAgencies && this.agency
            ? `current_agency_id = ${this.agency.id}`
            : undefined,
        facetFilters: [[`individual:${false}`]],
      }
      const response = await agents.search(this.inputValue, options)
      const hits = response.hits as Hit<AlgoliaAgent>[]
      this.agents = hits.map((hit) => Agent.fromAlgoliaHit(hit))
    } else return (this.agents = [])
  }

  @Watch("individual")
  resetAgent() {
    this.agentSelected = null
    this.inputValue = ""
    this.agents = []
  }

  updateAgent() {
    this.$emit("updateAgent", this.agentSelected)
  }
  openCreateAgent() {
    this.randomCreateAgentKey = Math.random()
    // @ts-ignore
    setTimeout(() => {
      const modal = this.$refs.createAgent as CreateAgent
      modal.open()
    }, 0)
  }
  agentCreated(agent: Agent) {
    setTimeout(() => {
      this.inputValue = agent.fullname
      this.searchAllAgencies = false
      this.onChangeInput()
    }, 1000)
  }
}
