


























import { Vue, Component, Prop } from "vue-property-decorator"
import House from "~/models/House"
import OcularPhone from "~/components/OcularPhone.vue"
import ClassifiedAdSummary from "../../house/ClassifiedAdSummary.vue"

@Component({
  components: {
    OcularPhone,
    ClassifiedAdSummary,
  },
})
export default class ClassifiedAds extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ required: false, default: [] }) selection!: Array<number>
  classifiedAdSelected: number[] = this.selection

  rules = [(val: string) => val.length > 0]

  get noAssociatedAds() {
    return this.house.history.filter((history) => history.agency_id == null)
  }

  selectAll(state: boolean) {
    this.classifiedAdSelected = []
    if (state)
      this.noAssociatedAds.forEach((advert) => {
        if (advert.id) this.classifiedAdSelected.push(advert.id)
      })
    this.updateClassifiedAdList()
  }
  updateClassifiedAdList() {
    this.$emit("updateClassifiedAdIds", this.classifiedAdSelected)
  }
}
